.svg_link {
  text-decoration: none;

  svg {
    opacity: 1;
    fill: slategray;

    &:hover {
      fill: royalblue;
    }
  }
}
