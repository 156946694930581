.carousel_slide {
  width: 100%;
  .carousel_image {
    object-fit: contain;
  }

  .image_carousel_item {
    height: 40vh;
  }
}
