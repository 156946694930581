.projectCard {
  transition: 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  img {
    height: "225px";
    width: "100%";
    display: "block";
  }

  .chevron_button {
    color: black;
    svg {
      position: relative;
      bottom: 0.1rem;
    }
  }
}
